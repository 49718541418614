import { ArrowDownward, KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { Avatar, Box, Button, colors, Fab, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ScrollTop from "./scroll-top";


export default function ChatBody({ type, countNewMessages, setCountNewMessages, messages, isOpenTextSection, color, textColorPalette, colorBars }) {
    const [shouldShowNewMessagesButton, setShouldShowNewMessagesButton] = useState(false);
    const [isScrollUp, setIsScrollUp] = useState(false);
    const [headerRef, setHeaderRef] = useState(undefined);
    let currentAvatar = null;

    useEffect(() => {
        if (headerRef) {
            const threshold = 0;
            let lastScrollY = headerRef.scrollTop;
            let ticking = false;

            const updateIsScrolling = () => {
                const scrollY = headerRef.scrollTop;

                if (Math.abs(scrollY - lastScrollY) < threshold) {
                    ticking = false;
                    return;
                }

                setIsScrollUp(scrollY > lastScrollY ? false : true);
                lastScrollY = scrollY > 0 ? scrollY : 0;
                ticking = false;

                const bottomElemnt = document.querySelector("#back-to-down-anchor");
                if (bottomElemnt && headerRef) {
                    const isScrollOnBottom = bottomElemnt.offsetTop > (headerRef.scrollTop + headerRef.clientHeight);
                    setShouldShowNewMessagesButton(isScrollOnBottom);
                    if (countNewMessages > 0 && isScrollOnBottom) {
                        setCountNewMessages(0);
                    }
                }
            };

            const onScroll = () => {
                if (!ticking) {
                    window.requestAnimationFrame(updateIsScrolling);
                    ticking = true;
                }
            };

            headerRef.addEventListener("scroll", onScroll);

            return () => headerRef.removeEventListener("scroll", onScroll);
        }
    }, [isScrollUp, headerRef]);

    return <Box ref={node => {
            if (node) {
                setHeaderRef(node);
            }
        }} py={1.5} px={2} flexGrow={1} sx={{ position:'relative', overflowX: 'auto', mb: 2, width: { xs: '100%', md: isOpenTextSection ? '450px' : '100%' } }} height={window.innerHeight - (type === "game" ? 500 : 350)}>
        <div id="back-to-top-anchor"></div>
        {messages.map(message => {
            const currentColor = message.currentUser ? (color === 'black' ? colors.grey[800] : colors.grey[200]) : colorBars;
            const newAvatar = message.userId !== currentAvatar;
            let cardStyle = {
                backgroundColor: currentColor,
                position: 'relative',
                marginLeft: newAvatar ? '20px' : '84px',
                marginBottom: '10px',
                padding: '10px',
                width: '75%',
                textAlign: 'left',
                borderRadius: '10px',
                border: `1px solid ${currentColor}`,
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: `15px solid ${currentColor}`,
                    borderLeft: "15px solid transparent",
                    borderRight: "15px solid transparent",
                    top: "0",
                    left: "-15px"
                },
                "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: `17px solid ${currentColor}`,
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "-1px",
                    left: "-17px"
                }
            };

            if (message.currentUser) {
                cardStyle = {
                    ...cardStyle,
                    marginLeft: null,
                    marginRight: newAvatar ? '20px' : '84px',
                    "&:after": {
                        content: "''",
                        position: "absolute",
                        width: "0",
                        height: "0",
                        borderTop: `15px solid ${currentColor}`,
                        borderLeft: "15px solid transparent",
                        borderRight: "15px solid transparent",
                        top: "0",
                        right: "-15px"
                    },
                    "&:before": {
                        content: "''",
                        position: "absolute",
                        width: "0",
                        height: "0",
                        borderTop: `17px solid ${currentColor}`,
                        borderLeft: "16px solid transparent",
                        borderRight: "16px solid transparent",
                        top: "-1px",
                        right: "-17px"
                    }
                }
            }

            if (!newAvatar) {
                cardStyle = {
                    ...cardStyle,
                    "&:after": {},
                    "&:before": {}
                }
            }

            const avatarPhotoUrl = message.avatar ? `${process.env.REACT_APP_BACKEND_URL}/${message.avatar}` : message.photo;

            const cardMessage = <Box key={message.id} sx={{ display: 'flex', justifyContent: message.currentUser ? 'flex-end' : 'unset', width: '100%', mb: 1 }}>
                {!message.currentUser && newAvatar && (<Avatar alt={message.name} src={avatarPhotoUrl} sx={{ width: 64, height: 64 }} />)}
                <Box sx={cardStyle}>
                    {newAvatar && (<Box display="flex" flexDirection='row' justifyContent='space-between' color={textColorPalette}>
                        <Typography variant="body2" sx={{ lineBreak: "anywhere" }}>
                            <Box component="span" fontWeight="bold">{message.name}</Box>
                        </Typography>
                        {message.timestamp && (<Typography variant="body2">{message.timestamp}</Typography>)}
                    </Box>)}
                    {message.message && (<Box color={textColorPalette}>{message.loading ? (<>
                        <Box display="flex">
                            <Skeleton variant="circular" width={16} height={16} />
                            <Box ml={1}></Box>
                            <Skeleton variant="circular" width={16} height={16} />
                            <Box ml={1}></Box>
                            <Skeleton variant="circular" width={16} height={16} />
                        </Box>
                    </>) : message.message.split('<br/>').map(line => {
                        let msg = line;
                        if (msg.indexOf('<div class') > -1) {
                            msg = msg.replace(msg.substring(msg.indexOf('<div class'), msg.indexOf('</div>') + 6), '');
                        }
                        return <Box>{msg}</Box>;
                    })}</Box>)}
                    {message.image && (<Box>
                        <img src={message.image} style={{ maxWidth: "100%", maxHeight: '192px' }} />
                    </Box>)}
                </Box>
                {message.currentUser && newAvatar && (<Avatar alt={message.name} src={avatarPhotoUrl} sx={{ width: 64, height: 64 }} />)}
            </Box>;

            currentAvatar = message.userId;

            return cardMessage;
        })}
        <div id="back-to-down-anchor"></div>
        {countNewMessages > 0 && shouldShowNewMessagesButton && (<Box role="presentation" sx={{ position: 'sticky', zIndex: 99, bottom: '0px', right: '0px', display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="info" size="small" startIcon={<ArrowDownward />} onClick={() => {
                document.querySelector("back-to-down-anchor").scrollIntoView({
                    block: 'center',
                });
            }}>{countNewMessages} new message{countNewMessages > 1 ? 's' : ''}</Button>
        </Box>)}
        {isScrollUp ? (<ScrollTop window={headerRef} targetId="#back-to-top-anchor">
            <Fab size="small" aria-label="scroll back to top">
                <KeyboardArrowUp />
            </Fab>
        </ScrollTop>) :
        (<ScrollTop window={headerRef} targetId="#back-to-down-anchor" down={true}>
            <Fab size="small" aria-label="scroll back to bottom">
                <KeyboardArrowDown />
            </Fab>
        </ScrollTop>)}
    </Box>;
}