import { useEffect, useState } from "react";
import { Alert, Avatar, Badge, Box, Card, CardContent, CardMedia, Chip, colors, Dialog, DialogContent, DialogTitle, Divider, Drawer, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Rating, Skeleton, Snackbar, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AccountBalanceWallet, AutorenewOutlined, BugReport, ChatBubble, Close, CoPresent, DoneAll, Edit, Home, Leaderboard, NotificationsActive, People, Person, PlayArrow, RateReview, RestartAlt, School, Star, StarBorder, StarHalf, ThumbsUpDown, Topic } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import ClassStyles from 'view/style';
import { PAGES } from "routes";
import { primary } from 'theme';
import { getUserInfo, updateUserInfo } from "infra/storage";
import NotificationsHome from "./notifications";
import LeaderboardBookCard from "./leaderboard-book";
import homeService from "infra/services/home";
import favoriteBookService from "infra/services/favorite-book";
import updateBookCurrentPageService from "infra/services/update-book-current-page";
import ProfileHome from "./profile";
import updateAvatarService from "infra/services/update-avatar";
import BookConfigSection from "view/book/config";

const getLeaderboardBadgeColor = currentuserInfo => {
    const { globalLeaderboardPosition, globalLeaderboardCount } = currentuserInfo || {};
    const percentage = globalLeaderboardPosition / globalLeaderboardCount;

    switch (true) {
        case (percentage < 0.34 || (globalLeaderboardCount < 3 && globalLeaderboardPosition === 1)):
            return "success";
        case (percentage < 0.68):
            return "warning";
        default:
            return "error";
    }
};

const CardSkeleton = ({ colorSidebar }) => (
    <Grid item xs={12} lg={6} xl={4}>
        <Box sx={{ pr: { md: 2 }, pb: 2 }}>
            <Card elevation={5} sx={{ backgroundColor: colorSidebar, display: 'flex', width: '100%', minHeight: '250px', minWidth: '250px' }}>
                <Skeleton variant="rectangular" height={250} sx={{ width: { xs: 75, md: 100, lg: 150 } }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pr: 2 }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            <Skeleton variant="text" />
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            <Skeleton variant="text" />
                        </Typography>
                        <Box mt={1.5}>
                            <Skeleton variant="text" />
                        </Box>
                    </CardContent>
                    <Box sx={{ pl: 1, pb: 1, width: '100%' }}>
                        <Skeleton variant="text" />
                    </Box>
                </Box>
            </Card>
        </Box>
    </Grid>
)

const LoadingContainer = ({ colorSidebar }) => (
    <Box>
        <Box pb={2}>
            <Typography variant="h3"><Skeleton variant="text" /></Typography>
            <Typography variant="h6"><Skeleton variant="text" /></Typography>
        </Box>
        <Grid container>
            <CardSkeleton colorSidebar={colorSidebar} />
            <CardSkeleton colorSidebar={colorSidebar} />
            <CardSkeleton colorSidebar={colorSidebar} />
        </Grid>
    </Box>
);

const HomeContainer = ({ currentuserInfo, setFeedbackDrawer, favoriteBookClick, colorSidebar, textColorPalette }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [currentClassIndex, setCurrentClassIndex] = useState(0);
    const [anchorMenuClass, setAnchorMenuClass] = useState(null);
    const openMenuClass = Boolean(anchorMenuClass);
    const handleOpenMenuClass = event => {
        setAnchorMenuClass(event.currentTarget);
    };
    const handleCloseMenuClass = () => {
        setAnchorMenuClass(null);
    };

    const { name, books } = currentuserInfo;

    return <>
        <Box pb={2}>
            <Typography variant="h3">Hi {name}!</Typography>
            <Typography variant="h6">
                <Box display="flex" alignItems="center">
                    {' '}
                </Box>
            </Typography>
        </Box>
        <Grid container>
            {books && books.map(book => {
                const openBookAction = () => navigate(PAGES.BOOK.replace(':id', book.id).replace(':page', book.currentPage > 0 ? book.currentPage : 1));
                const restartBookAction = () => navigate(`${PAGES.BOOK.replace(':id', book.id).replace(':page', 1)}?restart=true`);
                return <Grid key={book.id} item xs={12} lg={6} xl={3}>
                    <Box sx={{ pr: { md: 2 }, pb: 2 }}>
                        <Card elevation={5} sx={{ display: 'flex', backgroundColor: colorSidebar, color: textColorPalette, width: '100%', minHeight: '250px', minWidth: '250px' }}>
                            <Tooltip title={book.description} followCursor>
                                <CardMedia
                                    component="img"
                                    sx={{ width: { xs: 75, md: 100, lg: 150 }, cursor: 'pointer' }}
                                    image={`${process.env.REACT_APP_BACKEND_URL}/${book.coverLocation}`}
                                    alt="Live from space album cover"
                                    onClick={openBookAction}
                                />
                            </Tooltip>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pr: 2 }}>
                                <Tooltip title={book.description} followCursor>
                                    <CardContent sx={{ flex: '1 0 auto', cursor: 'pointer' }} onClick={openBookAction}>
                                        <Typography component="div" variant="subtitle1">
                                            {book.title}
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary" component="div" sx={{ color: textColorPalette }}>
                                            {book.author}
                                        </Typography>

                                        <Typography variant="caption" color="text.secondary" component="div" sx={{ color: textColorPalette }}>
                                            ID: {book.id}
                                        </Typography>
                                        <Box mt={1.5}>
                                            {book.currentPage === 0 ? <Chip color="error" label="Not Started" /> : book.progress === 1 ? <Chip color="success" label="Finished" /> : <Chip color="info" label={`Progress: ${Math.round(book.progress * 100)}%`} />}
                                        </Box>
                                        {book.currentPage > 0 && (<Box mt={1.5} display="flex" alignContent="center">
                                            Feedback: <Rating value={book.peerRating} readOnly precision={0.5} />
                                        </Box>)}
                                    </CardContent>
                                </Tooltip>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 1, pb: 1, width: '100%' }}>
                                    <IconButton title={book.isFavorite ? 'Unfavorite' : 'Favorite'} onClick={event => favoriteBookClick(book.id, event)} sx={{ color: textColorPalette }}>
                                        {book.isFavorite ? <Star /> : <StarBorder />}
                                    </IconButton>
                                    <IconButton title="Open the book" onClick={openBookAction} sx={{ color: textColorPalette }}>
                                        <PlayArrow />
                                    </IconButton>
                                    <IconButton title="Restart the book" onClick={restartBookAction} sx={{ color: textColorPalette }}>
                                        <RestartAlt />
                                    </IconButton>
                                    <IconButton disabled={book.countFeedback === 0} title="Feedbacks" onClick={() => setFeedbackDrawer(book.feedbacks)} sx={{ color: textColorPalette }}>
                                        <Badge badgeContent={book.countFeedback} color="error">
                                            <ThumbsUpDown />
                                        </Badge>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            })}
        </Grid>
    </>;
};

export default function HomePage() {
    const classCss = ClassStyles();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [currentuserInfo, setCurrentUserInfo] = useState(getUserInfo());
    const [error, setError] = useState('');
    const [notificationMenuAnchor, setNotificationMenuAnchor] = useState(null);
    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const handleOpenNotificationMenu = (event) => {
        setNotificationMenuAnchor(event.currentTarget)
    };
    const handleCloseProfileMenu = () => {
        setProfileMenuAnchor(null);
    };
    const handleOpenProfileMenu = (event) => {
        setProfileMenuAnchor(event.currentTarget)
    };
    const handleCloseNotificationMenu = () => {
        setNotificationMenuAnchor(null);
    };

    const [feedbackDrawer, setFeedbackDrawer] = useState(null);
    const [isOpenRankDrawer, setIsOpenRankDrawer] = useState(false);
    const [isOpenAvatarDialog, setIsOpenAvatarDialog] = useState(false);

    const getBook = () => {
        setLoading(true);
        homeService()
            .then(response => {
                const updateCurrentUser = {
                    ...getUserInfo(),
                    ...response
                };
                updateUserInfo(updateCurrentUser);
                setCurrentUserInfo(updateCurrentUser);
            })
            .catch(e => setError(e.errors[0].message))
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(getBook, []);

    const favoriteBookClick = (bookId, event) => {
        event.preventDefault();
        setLoading(true);
        favoriteBookService(bookId)
            .then(() => {
                getBook();
            })
            .catch(e => {
                setError(e.errors[0].message)
                setLoading(false);
            });
    }

    const getAvatarUrl = avatarId => `data/avatars/users/${avatarId}.jpg`;

    const handleAvatarChange = (avatarId = 1, event) => {
        event.preventDefault();
        setLoading(true);
        updateAvatarService(avatarId)
            .then(() => {
                const updateCurrentUser = {
                    ...currentuserInfo,
                    avatar: getAvatarUrl(avatarId)
                };
                setCurrentUserInfo(updateCurrentUser);
                updateUserInfo(updateCurrentUser);
                setIsOpenAvatarDialog(false);
            })
            .catch(e => {
                setError(e?.errors[0]?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOpenConfigMenuDrawer = () => {
        setIsOpenConfigMenuDrawer(true);
    };

    const userSettings = getUserInfo()?.settings;
    const [isOpenConfigMenuDrawer, setIsOpenConfigMenuDrawer] = useState(false);
    const [color, setColor] = useState(userSettings?.color || 'contrastText');
    const [textSize, setTextSize] = useState(userSettings?.textSize || 2);
    const [textVariant, setTextVariant] = useState(userSettings?.textVariant || 'body1');
    const [colorPalette, setColorPalette] = useState(userSettings?.colorPalette || theme.palette.primary.contrastText);
    const [textColorPalette, setTextColorPalette] = useState(userSettings?.textColorPalette || theme.palette.primary.dark);
    const [colorBars, setColorBars] = useState(userSettings?.colorBars || colors.grey[300]);
    const [colorSidebar, setColorSidebar] = useState(userSettings?.colorSidebar || theme.palette.primary.contrastTextDark);
    const handleColor = (_, newColor) => {
        if (newColor !== null) {
            setColorPalette({
                ['white']: theme.palette.light.main,
                ['black']: colors.grey[800],
                ['contrastText']: theme.palette.primary.contrastText
            }[newColor]);
            setTextColorPalette({
                ['white']: theme.palette.light.contrastText,
                ['black']: theme.palette.dark.contrastText,
                ['contrastText']: theme.palette.primary.dark
            }[newColor]);
            setColorBars({
                ['white']: colors.grey[300],
                ['black']: colors.grey[900],
                ['contrastText']: colors.grey[300]
            }[newColor]);
            setColorSidebar({
                ['white']: colors.grey[50],
                ['black']: colors.grey[700],
                ['contrastText']: theme.palette.primary.contrastTextDark
            }[newColor]);
            setColor(newColor);
        }
    };
    const [alignment, setAlignment] = useState(userSettings?.alignment || 'left');
    const handleAlignment = (_, newAlignment) => {
        setAlignment(newAlignment);
    };
    const [spacing, setSpacing] = useState(userSettings?.spacing || 'medium');
    const [spacingSize, setSpacingSize] = useState(userSettings?.spacingSize || 1.5);
    const handleSpacing = (_, newSpacing) => {
        setSpacing(newSpacing);
    };
    const [fullWidthText, setFullWidthText] = useState(userSettings?.fullWidthText || false);
    const handleFullWidthText = (event) => {
        setFullWidthText(event.target.checked);
    };
    const getTextVariant = (size) => ({
        [0]: () => 'caption',
        [1]: () => 'body2',
        [2]: () => 'body1',
        [3]: () => 'h6',
        [4]: () => 'h5',
        [5]: () => 'h4',
        [6]: () => 'h3',
        [7]: () => 'h2',
        [8]: () => 'h1',
    }[size]);

    const getSpacingSize = {
        ['small']: () => 1,
        ['medium']: () => 2,
        ['large']: () => 4
    }[spacing];

    const handleTextSizeChange = (_, textSizeChange) => {
        const increase = (textSizeChange === "increase");
        let newTextSize = textSize;
        if (increase && textSize < 8) {
            newTextSize++;
        } else if (!increase && textSize > 0) {
            newTextSize--;
        }
        setTextSize(newTextSize);
    };

    useEffect(() => {
        setTextVariant(getTextVariant(textSize));
        setSpacingSize(getSpacingSize);
    }, [textSize, spacing]);

    useEffect(() => {
        const settings = {
            ...getUserInfo()?.settings,
            color,
            textSize,
            textVariant,
            colorPalette,
            textColorPalette,
            colorBars,
            colorSidebar,
            alignment,
            spacing,
            spacingSize,
            fullWidthText
        };
        const updateCurrentUser = {
            ...getUserInfo(),
            settings
        };
        updateUserInfo(updateCurrentUser);
    }, [color, textSize, textVariant, colorPalette, textColorPalette, colorBars, colorSidebar, alignment, spacing, spacingSize, fullWidthText]);

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex" flexDirection="row" bgcolor={colorBars} color={textColorPalette}>
            <Box pl={3} flexGrow={1}>
                <Box width={64} height={64} className={classCss.logo}></Box>
            </Box>
            <Box>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="Bug Report"
                        title="Bug Report"
                        onClick={() => window.open('https://forms.gle/8YGetqpfRxy3q2Ay5')}
                    >
                        <BugReport />
                    </IconButton>
                    {currentuserInfo?.globalLeaderboardPosition && (<IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="Leaderboard"
                        title="Leaderboard"
                        onClick={() => setIsOpenRankDrawer(true)}
                    >
                        <Badge badgeContent={currentuserInfo.globalLeaderboardPosition} color={getLeaderboardBadgeColor(currentuserInfo)}>
                            <Leaderboard />
                        </Badge>
                    </IconButton>)}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="Notifications"
                        title="Notifications"
                        onClick={handleOpenNotificationMenu}
                        sx={{ display: 'none' }}
                    >
                        <Badge badgeContent={currentuserInfo.notifications.length} color="error">
                            <NotificationsActive />
                        </Badge>
                    </IconButton>
                    <NotificationsHome notificationMenuAnchor={notificationMenuAnchor} handleCloseNotificationMenu={handleCloseNotificationMenu} />
                    <IconButton
                        onClick={handleOpenProfileMenu}
                    >
                        <Avatar title={currentuserInfo.name} src={`${process.env.REACT_APP_BACKEND_URL}/${currentuserInfo.avatar}`} />
                    </IconButton>
                    <ProfileHome profileMenuAnchor={profileMenuAnchor} handleCloseProfileMenu={handleCloseProfileMenu} setIsOpenAvatarDialog={setIsOpenAvatarDialog} handleOpenConfigMenuDrawer={handleOpenConfigMenuDrawer} />
                </Toolbar>
            </Box>
        </Box>
        <Box flexGrow={1} py={3} px={3} bgcolor={colorPalette} color={textColorPalette}>
            {loading ? <LoadingContainer colorSidebar={colorSidebar} /> : <HomeContainer currentuserInfo={currentuserInfo} setFeedbackDrawer={setFeedbackDrawer} favoriteBookClick={favoriteBookClick} colorSidebar={colorSidebar} textColorPalette={textColorPalette} />}
        </Box>
        {feedbackDrawer && (<Drawer
            PaperProps={{ sx: {width: { xs: "100%", md: '450px'} }}}
            anchor="right"
            open={feedbackDrawer.length > 0}
            onClose={() => setFeedbackDrawer(null)}
        >
            <Box p={3} width={{ xs: "100%", md: '430px'}}>
                <Box display="flex" flexDirection='row' justifyContent="space-between">
                    <Box mx={2} flexGrow={1} fontWeight="bold" display="flex" alignItems="center" justifyContent="center">
                        Feedback
                    </Box>
                    <IconButton
                        edge="start"
                        color="primary"
                        aria-label="menu"
                        onClick={() => setFeedbackDrawer(null)}
                    >
                        <Close />
                    </IconButton>
                </Box>
                {feedbackDrawer.map((feedback, index) => (
                    <Box key={index}>
                        <Box pt={2} fontWeight="bold">
                            Feedback {index + 1}
                        </Box>
                        <Box mx={2} display="flex" flexDirection="row">
                            <Typography variant="legend">
                                Rating
                            </Typography>
                            <Rating value={feedback.rating} readOnly precision={0.5} />
                        </Box>
                        <Box mx={2} pt={2} display="flex" flexDirection="row">
                            <Typography variant="legend" fontWeight="bold">
                                Comment:
                            </Typography>
                        </Box>
                        <Box mx={2} mb={3}>
                            {feedback.comment}
                        </Box>
                        <Divider />
                    </Box>
                ))}
            </Box>
        </Drawer>)}
        {Array.isArray(currentuserInfo?.leaderboard?.general) && currentuserInfo?.leaderboard?.general.length > 0 && (
            <Drawer
                PaperProps={{ sx: {width: { xs: "100%", md: '450px'}, backgroundColor: colorSidebar, color: textColorPalette }}}
                anchor="right"
                open={isOpenRankDrawer}
                onClose={() => setIsOpenRankDrawer(false)}
            >
                <Box p={3} width={{ xs: "100%", md: '430px'}}>
                    <Box display="flex" flexDirection='row' justifyContent="space-between">
                        <Box mx={2} flexGrow={1} fontWeight="bold" display="flex" alignItems="center" justifyContent="center">
                            Leaderboard
                        </Box>
                        <IconButton
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            onClick={() => setIsOpenRankDrawer(false)}
                            sx={{ color: textColorPalette }}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                    {Array.isArray(currentuserInfo?.leaderboard?.general) && currentuserInfo?.leaderboard?.general.length > 0 && (
                        <LeaderboardBookCard type="general" leaderboard={currentuserInfo?.leaderboard?.general} colorBars={colorBars} textColorPalette={textColorPalette} />
                    )}
                    {currentuserInfo?.leaderboard && Object.keys(currentuserInfo?.leaderboard).map(bookId => {
                        if (bookId === 'general') {
                            return <></>;
                        }

                        const leaderboard = currentuserInfo.leaderboard[bookId];
                        const bookLeaderboard = currentuserInfo?.books?.filter(book => book.id === Number.parseInt(bookId))[0];

                        return <LeaderboardBookCard leaderboard={leaderboard} book={bookLeaderboard} colorBars={colorBars} textColorPalette={textColorPalette} />;
                    })}
                </Box>
            </Drawer>
        )}
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={error.length > 0}
            autoHideDuration={5000}
            onClose={() => setError('')}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
        </Snackbar>
        <Dialog open={isOpenAvatarDialog} onClose={() => setIsOpenAvatarDialog(false)}>
                <DialogTitle display="flex" textAlign="right" sx={{backgroundColor: colorBars, color: textColorPalette}}>
                    <Box>Change Avatar</Box>
                    <Box flexGrow={1} >
                        <IconButton onClick={() => setIsOpenAvatarDialog(false)}><Close /></IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{backgroundColor: colorSidebar, color: textColorPalette}}>
                    <Grid container>
                        {Array(currentuserInfo.maxAvatarId).fill(1).map((_, index) => <Grid item xs={3} key={index}>
                            <Avatar src={`${process.env.REACT_APP_BACKEND_URL}/${getAvatarUrl(index + 1)}`} sx={{ marginY: 2, cursor:"pointer" }} onClick={event => handleAvatarChange(index + 1, event)} />
                        </Grid>)}
                    </Grid>
                </DialogContent>
        </Dialog>
        {isOpenConfigMenuDrawer && (<BookConfigSection colorPalette={colorPalette} color={color} isOpenConfigMenuDrawer={isOpenConfigMenuDrawer} setIsOpenConfigMenuDrawer={setIsOpenConfigMenuDrawer} textColorPalette={textColorPalette} handleColor={handleColor} theme={theme} handleTextSizeChange={handleTextSizeChange} spacing={spacing} handleSpacing={handleSpacing} alignment={alignment} handleAlignment={handleAlignment} handleFullWidthText={handleFullWidthText} />)}
    </Box>;
}