import { useEffect, useState } from "react";
import { Alert, Avatar, AvatarGroup, Badge, Box, Button, Card, CardContent, CardMedia, Chip, CircularProgress, colors, Dialog, DialogContent, DialogTitle, Divider, Drawer, Fab, Grid, IconButton, LinearProgress, ListItemText, Menu, MenuItem, MenuList, Snackbar, SpeedDial, SpeedDialAction, Switch, TextField, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AccountBalanceWallet, ArrowBack, ArrowForward, Article, ArticleOutlined, ChatBubble, Close, CoPresent, DensityLarge, DensityMedium, DensitySmall, DoneAll, FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, Home, NotificationsActive, People, Person, PlayArrow, RateReview, RestartAlt, School, Send, Settings, TextDecrease, TextIncrease, Topic, Chat, MenuBook, Groups, ThumbsUpDown, Create, QuestionAnswer, DesignServices, Autorenew, SportsEsports, BugReport } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";

import ClassStyles from 'view/style';
import { PAGES } from "routes";
import BookDiscussionSection from "./discussion";
import BookChatSection from "./chat";
import BookConfigSection from "./config";
import NotificationsHome from "view/home/notifications";
import GameComponent from "./game";
import bookService from "infra/services/book";
import updateBookCurrentPageService from "infra/services/update-book-current-page";
import getChatService from "infra/services/get-chat";
import getPeerReviewService from "infra/services/get-peer-review";
import TextBook from "./text";
import { getUserInfo, updateUserInfo } from "infra/storage";
import ProfileHome from "view/home/profile";
import updateAvatarService from "infra/services/update-avatar";

export default function BookPage() {
    const classCss = ClassStyles();
    const { id } = useParams("id");
    let { page } = useParams("page");
    const restart = new URLSearchParams(window.location.search).get('restart');
    page = parseInt(page);
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userSettings = getUserInfo()?.settings;
    const [notificationMenuAnchor, setNotificationMenuAnchor] = useState(null);
    const handleOpenNotificationMenu = (event) => {
        setNotificationMenuAnchor(event.currentTarget)
    };
    const handleCloseNotificationMenu = () => {
        setNotificationMenuAnchor(null);
    };
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [book, setBook] = useState(null);
    const [chats, setChats] = useState(null);
    const [peerReviews, setPeerReviews] = useState(null);
    const hasGame = book?.games.length > 0;
    const currentPage = book?.pages[page];
    const [numberOfCrew, setNumberOfCrew] = useState(0);
    const [showCREW, setShowCREW] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [showPeerReview, setShowPeerReview] = useState(false);
    const [textSize, setTextSize] = useState(userSettings?.textSize || 2);
    const [textVariant, setTextVariant] = useState(userSettings?.textVariant || 'body1');
    const [isOpenTextSection, setIsOpenTextSection] = useState(true);
    const [isOpenChatDrawer, setIsOpenChatDrawer] = useState(false);
    const [isOpenCrewDiscussionDrawer, setIsOpenCrewDiscussionDrawer] = useState(false);
    const [shouldDisplayAlertCrew, setShouldDisplayAlertCrew] = useState(null);
    const [isPeerReview, setIsPeerReview] = useState(false);
    const [isOpenConfigMenuDrawer, setIsOpenConfigMenuDrawer] = useState(false);
    const [isGameOpen, setIsGameOpen] = useState(false);
    const [showGame, setShowGame] = useState(false);
    const [currentGame, setCurrentGame] = useState(null);
    const [gameType, setGameType] = useState('chip');
    const handleOpenConfigMenuDrawer = () => {
        setIsOpenConfigMenuDrawer(true);
    };
    const [color, setColor] = useState(userSettings?.userSettings || 'contrastText');
    const [colorPalette, setColorPalette] = useState(userSettings?.colorPalette || theme.palette.primary.contrastText);
    const [textColorPalette, setTextColorPalette] = useState(userSettings?.textColorPalette || theme.palette.primary.dark);
    const [colorBars, setColorBars] = useState(userSettings?.colorBars || colors.grey[300]);
    const [colorSidebar, setColorSidebar] = useState(userSettings?.colorSidebar || theme.palette.primary.contrastTextDark);
    const handleColor = (_, newColor) => {
        if (newColor !== null) {
            setColorPalette({
                ['white']: theme.palette.light.main,
                ['black']: colors.grey[800],
                ['contrastText']: theme.palette.primary.contrastText
            }[newColor]);
            setTextColorPalette({
                ['white']: theme.palette.light.contrastText,
                ['black']: theme.palette.dark.contrastText,
                ['contrastText']: theme.palette.primary.dark
            }[newColor]);
            setColorBars({
                ['white']: colors.grey[300],
                ['black']: colors.grey[900],
                ['contrastText']: colors.grey[300]
            }[newColor]);
            setColorSidebar({
                ['white']: colors.grey[50],
                ['black']: colors.grey[700],
                ['contrastText']: theme.palette.primary.contrastTextDark
            }[newColor]);
            setColor(newColor);
        }
    };
    const [alignment, setAlignment] = useState(userSettings?.alignment || 'left');
    const handleAlignment = (_, newAlignment) => {
        setAlignment(newAlignment);
    };
    const [spacing, setSpacing] = useState(userSettings?.spacing || 'medium');
    const [spacingSize, setSpacingSize] = useState(userSettings?.spacingSize || 1.5);
    const handleSpacing = (_, newSpacing) => {
        setSpacing(newSpacing);
    };
    const [fullWidthText, setFullWidthText] = useState(userSettings?.fullWidthText || false);
    const handleFullWidthText = (event) => {
        setFullWidthText(event.target.checked);
    };

    const [isFabMenuOpen, setIsFabMenuOpen] = useState(false);
    const handleOpenFabMenu = (event) => {
        setIsFabMenuOpen(true)
    };
    const handleCloseFabMenu = () => {
        setIsFabMenuOpen(false);
    };

    const getTextVariant = (size) => ({
        [0]: () => 'caption',
        [1]: () => 'body2',
        [2]: () => 'body1',
        [3]: () => 'h6',
        [4]: () => 'h5',
        [5]: () => 'h4',
        [6]: () => 'h3',
        [7]: () => 'h2',
        [8]: () => 'h1',
    }[size]);

    const getSpacingSize = {
        ['small']: () => 1,
        ['medium']: () => 2,
        ['large']: () => 4
    }[spacing];

    const handleTextSizeChange = (_, textSizeChange) => {
        const increase = (textSizeChange === "increase");
        let newTextSize = textSize;
        if (increase && textSize < 8) {
            newTextSize++;
        } else if (!increase && textSize > 0) {
            newTextSize--;
        }
        setTextSize(newTextSize);
    };

    useEffect(() => {
        setTextVariant(getTextVariant(textSize));
        setSpacingSize(getSpacingSize);
    }, [textSize, spacing]);

    useEffect(() => {
        const settings = {
            ...getUserInfo()?.settings,
            color,
            textSize,
            textVariant,
            colorPalette,
            textColorPalette,
            colorBars,
            colorSidebar,
            alignment,
            spacing,
            spacingSize,
            fullWidthText
        };
        const updateCurrentUser = {
            ...getUserInfo(),
            settings
        };
        updateUserInfo(updateCurrentUser);
    }, [color, textSize, textVariant, colorPalette, textColorPalette, colorBars, colorSidebar, alignment, spacing, spacingSize, fullWidthText]);

    const shouldShowTextSection = () => (
        (!isOpenChatDrawer && !isOpenCrewDiscussionDrawer && !showGame)
        || (isDesktop && isOpenChatDrawer)
        || (isDesktop && isOpenCrewDiscussionDrawer)
        || (isDesktop && showGame)
    );

    const getPeerReview = () => getPeerReviewService(id)
        .then(peerReviewResponse => setPeerReviews(peerReviewResponse))
        .catch(e => setError(e.errors[0].message));

    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const handleCloseProfileMenu = () => {
        setProfileMenuAnchor(null);
    };
    const handleOpenProfileMenu = (event) => {
        setProfileMenuAnchor(event.currentTarget)
    };
    const [isOpenAvatarDialog, setIsOpenAvatarDialog] = useState(false);

    const getAvatarUrl = avatarId => `data/avatars/users/${avatarId}.jpg`;

    const handleAvatarChange = (avatarId = 1, event) => {
        event.preventDefault();
        setLoading(true);
        updateAvatarService(avatarId)
            .then(() => {
                const updateCurrentUser = {
                    ...getUserInfo(),
                    avatar: getAvatarUrl(avatarId)
                };
                updateUserInfo(updateCurrentUser);
                setIsOpenAvatarDialog(false);
            })
            .catch(e => {
                setError(e?.errors[0]?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    useEffect(() => {
        setLoading(true);
        bookService(id)
            .then(bookResponse => {
                setBook(bookResponse);

                console.log(bookResponse);
                console.log(isNaN(bookResponse.totalPages));
                if (!bookResponse || isNaN(bookResponse.totalPages) || !bookResponse.totalPages) {
                    navigate(PAGES.HOME);
                }

                const game = bookResponse?.games.filter(game => Number.parseInt(game.page) === page);
                if (game?.length > 0) {
                    setIsGameOpen(true);
                    setShowGame(true);
                    setCurrentGame(game[0]);
                }

                const numActiveCrew = bookResponse?.discussions.filter(disc => Number.parseInt(disc.start_page) <= Number.parseInt(page) && disc.collaborative !== '0')?.length;
                const newCrew = bookResponse?.discussions.filter(disc => Number.parseInt(disc.start_page) == Number.parseInt(page) && disc.collaborative !== '0')?.length;
                const shouldShowPeerReview = bookResponse?.discussions.filter(disc => Number.parseInt(disc.start_page) <= Number.parseInt(page) && disc.collaborative !== '0')?.length;

                setShowPeerReview(shouldShowPeerReview > 0);
                setNumberOfCrew(numActiveCrew);
                setShouldDisplayAlertCrew(newCrew > 0);
                setShowChat(bookResponse?.discussions.length > 0);

                getPeerReview();

                getChatService(id)
                    .then(chatsResponse => {
                        setChats(chatsResponse);
                        setShowChat(chats && chats.topics?.length > 0);
                        setShowCREW(numActiveCrew > 0 && chatsResponse?.topics?.length > 0);
                    })
                    .catch(e => setError(e.errors[0].message));
            })
            .catch(e => setError(e.errors[0].message))
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => {
        updateBookCurrentPageService({
            bookId: id,
            pageId: page,
            restart: !!restart
        })
            .catch(e => setError(e?.errors[0]?.message));

        setShowChat(chats && chats.topics?.length > 0);
    }, [page]);

    useEffect(() => {
        const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
            getChatService(id)
                .then(chatsResponse => {
                    setChats(chatsResponse);
                    setShowChat(chats && chats.topics?.length > 0);
                    setShowCREW(numberOfCrew > 0 && chatsResponse?.topics?.length > 0);
                })
                .catch(e => setError(e.errors[0].message));

            if (showPeerReview) {
                getPeerReviewService(id)
                    .then(peerReviewResponse => setPeerReviews(peerReviewResponse))
                    .catch(e => setError(e.errors[0].message));
            }
        }, 5000);

        return () => clearInterval(intervalId); //This is important
    });

    if (loading) {
        return <Box width="100%" height="100%">
            <CircularProgress sx={{ position: "relative", top: "50%", left: "50%" }} />
        </Box>;
    }

    return <Box width="100%" height="100%" display="flex" flexDirection="column">
        {isDesktop && (<Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" bgcolor={colorBars} color={textColorPalette}>
                <Box pl={3}>
                    <Box width={64} height={64} className={classCss.logo} onClick={() => navigate(PAGES.HOME)} sx={{ cursor: 'pointer' }}></Box>
                </Box>
                <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
                    {isDesktop && (<Typography variant="h4">{book?.title}</Typography>)}
                </Box>
                <Box>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Home"
                            title="Home"
                            onClick={() => navigate(PAGES.HOME)}
                        >
                            <Home />
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Bug Report"
                            title="Bug Report"
                            onClick={() => window.open('https://forms.gle/8YGetqpfRxy3q2Ay5')}
                        >
                            <BugReport />
                        </IconButton>
                        {(showCREW || showChat) && (<IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Textbook"
                            title="Textbook"
                            onClick={() => setIsOpenTextSection(!isOpenTextSection)}
                        >
                            <MenuBook />
                        </IconButton>)}
                        {(showCREW || showChat) && (<IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Chat"
                            title="Chat"
                            onClick={() => setIsOpenChatDrawer(!isOpenChatDrawer)}
                        >
                            <Badge badgeContent={chats?.total_unread} color="error">
                                <QuestionAnswer />
                            </Badge>
                        </IconButton>)}
                        {showCREW && (<IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Co-write"
                            title="Co-write"
                            onClick={() => {
                                setIsOpenCrewDiscussionDrawer(!isOpenCrewDiscussionDrawer);
                                setIsPeerReview(false);
                            }}
                        >
                            <Badge badgeContent={numberOfCrew} color="error">
                                <RateReview />
                            </Badge>
                        </IconButton>)}
                        {showPeerReview && (<IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Review"
                            title="Review"
                            onClick={() => {
                                setIsOpenCrewDiscussionDrawer(!isOpenCrewDiscussionDrawer);
                                setIsPeerReview(true);
                            }}
                        >
                            <Badge badgeContent={peerReviews?.total_unfinished} color="error">
                                <ThumbsUpDown />
                            </Badge>
                        </IconButton>)}
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Notifications"
                            title="Notifications"
                            onClick={handleOpenNotificationMenu}
                            sx={{ display: 'none' }}
                        >
                            <Badge badgeContent={20} color="error">
                                <NotificationsActive />
                            </Badge>
                        </IconButton>
                        <NotificationsHome notificationMenuAnchor={notificationMenuAnchor} handleCloseNotificationMenu={handleCloseNotificationMenu} />
                        <IconButton onClick={handleOpenProfileMenu}>
                            <Avatar alt={getUserInfo().name} src={`${process.env.REACT_APP_BACKEND_URL}/${getUserInfo().avatar}`} />
                        </IconButton>
                        <ProfileHome profileMenuAnchor={profileMenuAnchor} handleCloseProfileMenu={handleCloseProfileMenu} setIsOpenAvatarDialog={setIsOpenAvatarDialog} handleOpenConfigMenuDrawer={handleOpenConfigMenuDrawer} />
                    </Toolbar>
                </Box>
            </Box>
        </Box>)}
        <Box display="flex" flexGrow={1} flexDirection="row" width="100%">
            {isOpenCrewDiscussionDrawer && (<BookDiscussionSection discussions={book?.discussions} chats={chats} bookId={id} peerReviews={peerReviews} getPeerReview={getPeerReview} setError={setError} isDesktop={isDesktop} isOpenChatDrawer={isOpenChatDrawer} isOpenTextSection={isOpenTextSection} setIsOpenCrewDiscussionDrawer={setIsOpenCrewDiscussionDrawer} colorSidebar={colorSidebar} textColorPalette={textColorPalette} color={color} colorPalette={colorPalette} isPeerReview={isPeerReview} />)}
            {shouldShowTextSection() && isOpenTextSection && (<>
                <Box display="flex" flexDirection="column" flexGrow={1} bgcolor={colorPalette} color={textColorPalette}>
                    <Box flexGrow={1} height={window.innerHeight - (isDesktop ? 64 : 0) - 40} sx={{ overflowY: 'auto' }}>
                        <Box py={3} sx={{ height: 'inherit', width: 'inherit' }} px={{ xs: 3, md: fullWidthText || (isDesktop && isOpenChatDrawer) || (isDesktop && isGameOpen) || (isDesktop && isOpenCrewDiscussionDrawer) || currentPage?.externalGame ? 3 : "25%" }}>
                            {isDesktop && (isOpenChatDrawer || isOpenCrewDiscussionDrawer) && (<Box display="flex" flexDirection='row' justifyContent="end">
                                <IconButton
                                    edge="start"
                                    color="primary"
                                    aria-label="menu"
                                    onClick={() => setIsOpenTextSection(false)}
                                    sx={[color === 'black' && { color: colors.grey[50] }]}
                                >
                                    <Close />
                                </IconButton>
                            </Box>)}
                            {!isDesktop && (<Typography variant={getTextVariant(textSize + 1)()}>
                                <Box component="span" fontWeight="bold">{book?.title}</Box>
                            </Typography>)}
                            {currentPage && currentPage?.text && currentPage?.text?.split('<p>').map((paragraph, key) => (
                                <Box key={key} pb={3} textAlign={alignment}>
                                    <Typography variant={textVariant} lineHeight={spacingSize}>
                                        <TextBook text={paragraph} dictionary={book.dictionary} />
                                    </Typography>
                                </Box>
                            ))}
                            {currentPage && currentPage?.externalGame && (
                                <iframe src={`${process.env.REACT_APP_BACKEND_URL}/${currentPage?.externalGame}`} id="c2game" style={{ width:'100%', height:'100%', border:0}}></iframe>
                            )}
                        </Box>
                    </Box>
                    <Box width="100%" display="flex" flexDirection="row"  bgcolor={colorBars} color={textColorPalette}>
                        <IconButton
                            disabled={page === 1}
                            onClick={() => {
                                const previousPage = page - 1;
                                const numActiveCrew = book?.discussions.filter(disc => Number.parseInt(disc.start_page) <= Number.parseInt(previousPage) && disc.collaborative !== '0')?.length;
                                const newCrew = book?.discussions.filter(disc => Number.parseInt(disc.start_page) == Number.parseInt(previousPage) && disc.collaborative !== '0')?.length;
                                const shouldShowPeerReview = book?.discussions.filter(disc => Number.parseInt(disc.start_page) <= Number.parseInt(previousPage) && disc.collaborative !== '0')?.length;

                                setShowPeerReview(shouldShowPeerReview > 0);
                                setNumberOfCrew(numActiveCrew);
                                setShowCREW(numActiveCrew > 0 && chats?.topics?.length > 0);
                                setShouldDisplayAlertCrew(newCrew > 0);
                                navigate(PAGES.BOOK.replace(':id', book?.id).replace(':page', previousPage))
                            }}
                        >
                            <ArrowBack sx={{ color: textColorPalette }} />
                        </IconButton>
                        <Box px={3} display="flex" flexDirection="row" flexGrow={1} alignItems="center" justifyContent="center">
                            <Typography variant="body2">Page {page}/{book?.totalPages}</Typography>
                            <Box px={2} flexGrow={1}>
                                <LinearProgress variant="determinate" value={Math.round(page/book?.totalPages * 100)} sx={[color === 'black' && { backgroundColor: colors.grey[700], '& .MuiLinearProgress-bar1Determinate': { backgroundColor: colors.grey[50] } }]} />
                            </Box>
                            <Box color={textColorPalette} sx={{ minWidth: 35 }}>
                                <Typography variant="body2">{Math.round(page/book?.totalPages * 100)}%</Typography>
                            </Box>
                        </Box>
                        {isGameOpen && page >= currentGame?.page ? (
                            <Box px={1}></Box>
                        ) : (<IconButton
                            onClick={() => {
                                if (page === Number.parseInt(book?.totalPages)) {
                                    navigate(PAGES.HOME);
                                    return;
                                }
                                const nextPage = page + 1;
                                const game = book?.games.filter(game => Number.parseInt(game.page) === nextPage);
                                if (hasGame && game?.length > 0) {
                                    setIsGameOpen(true);
                                    setShowGame(true);
                                    setCurrentGame(game[0]);
                                } else {
                                    setCurrentGame(null);
                                }
                                const numActiveCrew = book?.discussions.filter(disc => Number.parseInt(disc.start_page) <= Number.parseInt(nextPage) && disc.collaborative !== '0')?.length;
                                const newCrew = book?.discussions.filter(disc => Number.parseInt(disc.start_page) == Number.parseInt(nextPage) && disc.collaborative !== '0')?.length;
                                const shouldShowPeerReview = book?.discussions.filter(disc => Number.parseInt(disc.start_page) <= Number.parseInt(nextPage) && disc.collaborative !== '0')?.length;

                                setShowPeerReview(shouldShowPeerReview > 0);
                                setNumberOfCrew(numActiveCrew);
                                setShowCREW(numActiveCrew > 0 && chats?.topics?.length > 0);
                                setShouldDisplayAlertCrew(newCrew > 0);
                                navigate(PAGES.BOOK.replace(':id', book.id).replace(':page', nextPage))
                            }}
                        >
                            {page === Number.parseInt(book?.totalPages) ? (<Home sx={{ color: textColorPalette }} />) : (<ArrowForward sx={{ color: textColorPalette }} />)}
                        </IconButton>)}
                    </Box>
                </Box>
            </>)}
            {isOpenConfigMenuDrawer && (<BookConfigSection colorPalette={colorPalette} color={color} isOpenConfigMenuDrawer={isOpenConfigMenuDrawer} setIsOpenConfigMenuDrawer={setIsOpenConfigMenuDrawer} textColorPalette={textColorPalette} handleColor={handleColor} theme={theme} handleTextSizeChange={handleTextSizeChange} spacing={spacing} handleSpacing={handleSpacing} alignment={alignment} handleAlignment={handleAlignment} handleFullWidthText={handleFullWidthText} />)}
            {isOpenChatDrawer && (<BookChatSection discussions={book?.discussions} chats={chats} bookId={id} page={page} setError={setError} isDesktop={isDesktop} isOpenTextSection={isOpenTextSection} isOpenCrewDiscussionDrawer={isOpenCrewDiscussionDrawer} setIsOpenChatDrawer={setIsOpenChatDrawer} colorPalette={colorPalette} colorSidebar={colorSidebar} textColorPalette={textColorPalette} color={color} colorBars={colorBars} />)}
            {showGame && (
                <GameComponent currentGame={currentGame} gameType={gameType} setGameType={setGameType} isDesktop={isDesktop} setShowGame={setShowGame} setIsGameOpen={setIsGameOpen} isOpenTextSection={isOpenTextSection} color={color} colorSidebar={colorSidebar} textColorPalette={textColorPalette} colorBars={colorBars} />
            )}
            {!isDesktop && (<SpeedDial
                ariaLabel="Teste"
                open={isFabMenuOpen}
                onOpen={handleOpenFabMenu}
                onClose={handleCloseFabMenu}
                sx={{ position: 'absolute', bottom: shouldShowTextSection() ? 56 : 16, right: 16, '& .MuiSpeedDial-fab': { bgcolor: colorPalette, height: 64, width: 64, '& :hover': { bgcolor: colorBars, borderRadius: '50%' } } }}
                icon={<Box width={64} height={64} className={classCss.logo}>&nbsp;</Box>}
            >
                <SpeedDialAction
                    icon={<Home />}
                    tooltipTitle="Home"
                    tooltipOpen
                    onClick={() => navigate(PAGES.HOME)}
                />
                <SpeedDialAction
                    icon={<BugReport />}
                    tooltipTitle="Bug Report"
                    tooltipOpen
                    onClick={() => window.open('https://forms.gle/8YGetqpfRxy3q2Ay5')}
                />
                {(showCREW || showChat || isGameOpen) && (<SpeedDialAction
                    icon={<MenuBook />}
                    tooltipTitle='Textbook'
                    tooltipOpen
                    onClick={() => {
                        setIsOpenTextSection(true);
                        if (!isDesktop) {
                            setIsOpenCrewDiscussionDrawer(false);
                            setIsOpenChatDrawer(false);
                            setShowGame(false);
                        }
                    }}
                />)}
                <SpeedDialAction
                    icon={<Settings />}
                    tooltipTitle='Config'
                    tooltipOpen
                    onClick={handleOpenConfigMenuDrawer}
                />
                {showChat && (<SpeedDialAction
                    icon={<Badge badgeContent={chats?.total_unread} color="error">
                        <QuestionAnswer />
                    </Badge>}
                    tooltipTitle='Chat'
                    tooltipOpen
                    onClick={() => {
                        setIsOpenChatDrawer(!isOpenChatDrawer);
                        if (!isDesktop) {
                            setIsOpenCrewDiscussionDrawer(false);
                            setIsOpenTextSection(false);
                        }
                    }}
                />)}
                {showCREW && (<SpeedDialAction
                    icon={<Badge badgeContent={numberOfCrew} color="error">
                        <RateReview />
                    </Badge>}
                    tooltipTitle='Co-write'
                    tooltipOpen
                    onClick={() => {
                        setIsOpenCrewDiscussionDrawer(!isOpenCrewDiscussionDrawer);
                        setIsPeerReview(false);
                        if (!isDesktop) {
                            setIsOpenChatDrawer(false);
                            setIsOpenTextSection(false);
                        }
                    }}
                />)}
                {showPeerReview && (<SpeedDialAction
                    icon={<Badge badgeContent={peerReviews?.total_unfinished} color="error">
                        <ThumbsUpDown />
                    </Badge>}
                    tooltipTitle='Review'
                    tooltipOpen
                    onClick={() => {
                        setIsOpenCrewDiscussionDrawer(!isOpenCrewDiscussionDrawer);
                        setIsPeerReview(true);
                        if (!isDesktop) {
                            setIsOpenChatDrawer(false);
                            setIsOpenTextSection(false);
                        }
                    }}
                />)}
                {isGameOpen && (<SpeedDialAction
                    icon={<SportsEsports />}
                    tooltipTitle='Game'
                    tooltipOpen
                    onClick={() => {
                        setShowGame(true);
                        if (!isDesktop) {
                            setIsOpenChatDrawer(false);
                            setIsOpenTextSection(false);
                        }
                    }}
                />)}
                {isGameOpen && (<SpeedDialAction
                    icon={<Autorenew />}
                    tooltipTitle='Game experience'
                    tooltipOpen
                    onClick={() => {
                        setGameType(gameType === "chip" ? "dnd" : "chip");
                    }}
                />)}
            </SpeedDial>)}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={shouldDisplayAlertCrew && error.length === 0}
                autoHideDuration={5000}
                onClose={() => setShouldDisplayAlertCrew(false)}>
                    <Alert severity="info" sx={{ width: '100%' }}>
                        You have a Co-write activity available
                    </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={error.length > 0}
                autoHideDuration={5000}
                onClose={() => setError('')}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
            </Snackbar>
        </Box>
        <Dialog open={isOpenAvatarDialog} onClose={() => setIsOpenAvatarDialog(false)}>
                <DialogTitle display="flex" textAlign="right">
                    <Box>Change Avatar</Box>
                    <Box flexGrow={1} >
                        <IconButton><Close /></IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        {Array(getUserInfo().maxAvatarId).fill(1).map((_, index) => <Grid item xs={3} key={index}>
                            <Avatar src={`${process.env.REACT_APP_BACKEND_URL}/${getAvatarUrl(index + 1)}`} sx={{ marginBottom: 2, cursor:"pointer" }} onClick={event => handleAvatarChange(index + 1, event)} />
                        </Grid>)}
                    </Grid>
                </DialogContent>
        </Dialog>
    </Box>;
}