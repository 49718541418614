import * as React from 'react';
import { Box, Fade } from "@mui/material";

function defaultTrigger(store, down, bottomObj, options) {
    const { disableHysteresis = false, threshold = 100, target } = options;
    const previous = store.current;
  
    if (target) {
      // Get vertical scroll
      store.current = target.pageYOffset !== undefined ? target.pageYOffset : target.scrollTop;
    }

    if (down) {
        if (bottomObj && target) {
            return bottomObj.offsetTop > (target.scrollTop + target.clientHeight);
        }

        return false;
    }
  
    return store.current > threshold;
  }
  
  const defaultTarget = typeof window !== 'undefined' ? window : null;
  
  function useScrollTrigger(options = {}) {
    const { getTrigger = defaultTrigger, target = defaultTarget, bottomObj = defaultTarget, down = false, ...other } = options;
    const store = React.useRef();
    const [trigger, setTrigger] = React.useState(() => getTrigger(store, down, bottomObj, other));
  
    React.useEffect(() => {
      const handleScroll = () => {
        setTrigger(getTrigger(store, down, bottomObj, { target, ...other }));
      };
  
      handleScroll(); // Re-evaluate trigger when dependencies change
      target.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        target.removeEventListener('scroll', handleScroll, { passive: true });
      };
      // See Option 3. https://github.com/facebook/react/issues/14476#issuecomment-471199055
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target, getTrigger, JSON.stringify(other)]);
  
    return trigger;
  }

export default function ScrollTop(props) {
    const { children, window, targetId, down } = props;

    const trigger = useScrollTrigger({
        target: window ? window : undefined,
        bottomObj: document.querySelector(targetId),
        // disableHysteresis: true,
        down,
        threshold: 10
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            targetId,
          );
      
          if (anchor) {
            anchor.scrollIntoView({
              block: 'center',
            });
          }
    };

    return <Fade in={trigger}>
        <Box onClick={handleClick} role="presentation" sx={{ position: 'sticky', zIndex: 99, bottom: '0px', right: '0px', display: 'flex', justifyContent: 'flex-end' }}>
            {children}
        </Box>
    </Fade>
}