import api from 'infra/config/api';

export default function updateAvatarService(avatarId) {
    const url = '/update-user-avatar.php';
    return api
        .url(url)
        .post({
            avatarId
        })
        .text();
}