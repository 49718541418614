import { ArrowBack, Close, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, AvatarGroup, Badge, Box, Button, Card, CardMedia, CardContent, Chip, colors, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import postNewChatComment from "infra/services/new-chat-comment";
import { createRef, useEffect, useState } from "react";
import ChatBody from "./chat.body";

export default function BookChatSection({ discussions, chats, bookId, page, setError, isDesktop, isOpenTextSection, isOpenCrewDiscussionDrawer, setIsOpenChatDrawer, textColorPalette, colorSidebar, colorPalette, color, colorBars }) {
    const [currentChat, setCurrentChat] = useState(null);
    const [currentDiscussion, setCurrentDiscussion] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [countNewMessages, setCountNewMessages] = useState(0);
    const newCommentRef = createRef();

    const handleChangeInput = event => {
        setNewComment(event.target.value);
    }

    const handleSubmitNewComment = event => {
        event.preventDefault();
        setLoading(true);
        postNewChatComment({
            bookId,
            page,
            topicId: currentChat.topic_id,
            newComment
        })
            .catch(e => setError(e.errors[0].message))
            .finally(() => setLoading(false));
        newCommentRef.current.value = '';
    };

    useEffect(() => {
        if (discussions && discussions.length === 1) {
            const onlyChat = chats.topics[0];
            setCurrentChat(onlyChat);
            setCountNewMessages(onlyChat.unread);
            setCurrentDiscussion(discussions.filter(discussion => discussion.topic_id == onlyChat.topic_id)[0]);
        }

        if (currentChat) {
            const updatedCurrentChat = chats.topics.find(topic => topic.topic_id === currentChat.topic_id);
            setCurrentChat(updatedCurrentChat);
            setCountNewMessages(updatedCurrentChat.unread);
            setCurrentDiscussion(discussions.filter(discussion => discussion.topic_id == updatedCurrentChat.topic_id)[0]);
        }
    }, [chats]);

    if (!discussions || !chats) {
        return <></>;
    }

    return <Box px={3} pt={3} pb={4.5} display="flex" flexDirection="column" height="100%" bgcolor={colorSidebar} color={textColorPalette}
        sx={{ width: { md: isOpenTextSection ? '500px' : '100%' } }}
    >
        {((isDesktop && (isOpenCrewDiscussionDrawer || isOpenTextSection)) || currentChat) && (<Box mb={2} display="flex" flexDirection='row' justifyContent={currentChat ? "space-between" : "end"}>
            {currentChat && (
                <IconButton
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    onClick={() => {
                        setCurrentChat(null);
                        setCurrentDiscussion(null);
                    }}
                    sx={[color === 'black' && { color: colors.grey[50] }]}
                >
                    <ArrowBack />
                </IconButton>
            )}
            <Box mx={2} flexGrow={1} fontWeight="bold" display="flex" alignItems="center" justifyContent="center">
                {currentDiscussion && currentDiscussion.question ? currentDiscussion.question : 'Chat'}
            </Box>
            {isDesktop && (isOpenCrewDiscussionDrawer || isOpenTextSection) && (<IconButton
                edge="start"
                color="primary"
                aria-label="menu"
                onClick={() => setIsOpenChatDrawer(false)}
                sx={[color === 'black' && { color: colors.grey[50] }]}
            >
                <Close />
            </IconButton>)}
        </Box>)}
        {currentChat && (<>
            <Box flexGrow={1} sx={{ overflowX: 'auto', mb: 2, width: { xs: '100%', md: isOpenTextSection ? '450px' : '100%' } }}>
                <ChatBody countNewMessages={countNewMessages} setCountNewMessages={setCountNewMessages} messages={currentChat.messages} isOpenTextSection={isOpenTextSection} color={color} textColorPalette={textColorPalette} colorBars={colorBars} />
            </Box>
            <Grid container>
                <Grid item xs={8}>
                    <TextField inputRef={newCommentRef} fullWidth onChange={handleChangeInput} disabled={loading} label="Type here to write a comment." helperText="Click the Send Message button to submit it to the discussion." FormHelperTextProps={{sx: { color: textColorPalette }}} InputLabelProps={{sx: { color: textColorPalette }}} sx={{ '& .MuiOutlinedInput-notchedOutline': {borderColor: textColorPalette}}} />
                </Grid>
                <Grid item xs={4} sx={{ pl: 2 }}>
                    <LoadingButton fullWidth variant="contained" startIcon={<Send />} loading={loading} disabled={!newComment} onClick={handleSubmitNewComment}>Send</LoadingButton>
                </Grid>
            </Grid>
        </>)}
        {!currentChat && (<Box flexGrow={1} sx={{ overflowX: 'auto', mb: 2, width: { xs: '100%', md: isOpenTextSection ? '450px' : '100%' } }}>
            {chats.topics.map((chat, key) => {
                let discussionUsers = [];
                chat.messages.map(chatMessage => {
                    if (discussionUsers.filter(user => user.user_name === chatMessage.user_name).length === 0) {
                        discussionUsers.push({
                            user_name: chatMessage.user_name,
                            avatar : chatMessage.avatar,
                            currentUser: chatMessage.currentUser
                        });
                    }
                });
                const chatDiscussion = discussions.filter(discussion => discussion.topic_id == chat.topic_id)[0];

                return <Card key={`chat_${key}`} sx={{ display: 'flex', width: '100%', mb: 3, backgroundColor: colorPalette, cursor: 'pointer' }} onClick={() => {
                        setCurrentChat(chat);
                        setCountNewMessages(chat.unread);
                        setCurrentDiscussion(chatDiscussion);
                    }}>
                    <Box display='flex' alignItems="center">
                        <AvatarGroup max={3} total={discussionUsers.length} sx={{ width: 130 }}>
                            {discussionUsers[0] && (<Avatar alt={discussionUsers[0].user_name} src={`${process.env.REACT_APP_BACKEND_URL}/${discussionUsers[0].avatar}`} />)}
                            {discussionUsers[1] && (<Avatar alt={discussionUsers[1].user_name} src={`${process.env.REACT_APP_BACKEND_URL}/${discussionUsers[1].avatar}`} />)}
                        </AvatarGroup>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Badge badgeContent={chat.unread} color="error">
                                <Box color={textColorPalette}>{chatDiscussion.question}</Box>
                            </Badge>
                        </CardContent>
                    </Box>
                </Card>
            })}
        </Box>)}
    </Box>;
}