import { Send } from "@mui/icons-material";
import { Box, Button, Chip, Grid } from "@mui/material";
import { useEffect, useState } from "react";

import { getUserInfo } from 'infra/storage';

export default function TesteChipGameTest({ options, messages, setMessages, isDisabledSendButton, newMessage, setNewMessage}) {
    const [selectText, setSelectText] = useState([]);
    const [newState, setNewState] = useState(null);
    const [deleteState, setDeleteState] = useState(null);

    const handleDeleteChip = text => {
        setDeleteState(text);
    }

    const handleClick = text => {
        setNewState(text);
    };

    useEffect(() => {
        if (newState) {
            let aux = selectText;
            aux.push(newState);
            console.log(aux);
            setSelectText(aux);
            setNewState(null);
        }
        if (deleteState) {
            const aux = selectText.filter(chip => chip !== deleteState);
            setSelectText(aux);
            setDeleteState(null);
        }
    }, [newState, deleteState]);

    return <>
        <Grid container mb={2}>
            <Grid item xs={8}>
                {selectText.length === 0 ? <Box>Please, click on the buttons bellow to build your phrase</Box> : selectText.map(text => (
                    <Chip
                        key={text}
                        label={text}
                        onClick={() => handleDeleteChip(text)}
                        onDelete={() => handleDeleteChip(text)}
                        sx={{ maxWidth: 'inherit' }}
                    />
                ))}
            </Grid>
            <Grid item xs={4}>
                <Button fullWidth variant="contained" startIcon={<Send />} disabled={selectText.length === 0 || isDisabledSendButton}
                    onClick={() => {
                        let aux = messages;
                        aux.push({
                            photo: `${process.env.REACT_APP_BACKEND_URL}/${getUserInfo().avatar}`,
                            name: 'You',
                            currentUser: true,
                            loading: false,
                            message: selectText.join(' ')
                        });
                        setMessages(aux);
                        setNewMessage(!newMessage);
                        setSelectText([]);
                    }}
                >
                    Send
                </Button>
            </Grid>
        </Grid>
        <Grid container>
            {options.map(option => {
                let color = "primary";
                if (Number.parseInt(option.typeId) === 2) {
                    color = "success";
                } else if (Number.parseInt(option.typeId) === 3) {
                    color = "error";
                } else if (Number.parseInt(option.typeId) === 4) {
                    color = "warning";
                } else if (Number.parseInt(option.typeId) === 5) {
                    color = "info";
                }

                return <Grid item key={option.word} mr={1} mb={1}>
                    <Button
                        variant="contained"
                        color={color}
                        onClick={() => handleClick(option.word)}
                        sx={{ textTransform: 'none' }}
                    >
                        {option.word}
                    </Button>
                </Grid>;
            })}
        </Grid>
    </>;
}