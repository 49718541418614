import { Avatar, AvatarGroup, Badge, Box, Button, Card, CardContent, CardMedia, Chip, colors, Divider, Drawer, Grid, IconButton, LinearProgress, ListItemText, Menu, MenuItem, MenuList, Switch, TextField, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AccountBalanceWallet, ArrowBack, ArrowForward, ChatBubble, Close, CoPresent, DensityLarge, DensityMedium, DensitySmall, DoneAll, FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, Home, NotificationsActive, People, Person, PlayArrow, RestartAlt, School, Send, Settings, TextDecrease, TextIncrease, Topic } from "@mui/icons-material";

export default function BookConfigSection({
    colorPalette,
    color,
    isOpenConfigMenuDrawer,
    setIsOpenConfigMenuDrawer,
    textColorPalette,
    handleColor,
    theme,
    handleTextSizeChange,
    spacing,
    handleSpacing,
    alignment,
    handleAlignment,
    handleFullWidthText
}) {
    return <Drawer
        PaperProps={{ sx: {width: { xs: "70%", md: '400px'}, backgroundColor: `${colorPalette} !important` } }}
        ModalProps={{ sx: [color === 'black' && { '& .MuiBackdrop-root': { backgroundColor: colors.grey[50], opacity: '0.6 !important'} }] }}
        anchor="right"
        open={isOpenConfigMenuDrawer !== null}
        onClose={() => setIsOpenConfigMenuDrawer(null)}
    >
    <Box p={3} width={{ xs: "100%", md: '400px'}} color={textColorPalette}>
        <Box display="flex" flexDirection='row' justifyContent="end">
            <IconButton
                edge="start"
                color="primary"
                aria-label="menu"
                onClick={() => setIsOpenConfigMenuDrawer(null)}
                sx={[color === 'black' && { color: colors.grey[50] }]}
            >
                <Close />
            </IconButton>
        </Box>
        <Box pt={2} fontWeight="bold">
            Background Color
        </Box>
        <Box mx={2} display="flex" flexDirection="row">
            <ToggleButtonGroup
                value={color}
                exclusive
                onChange={handleColor}
                aria-label="background color"
                fullWidth={true}
            >
                <ToggleButton value="white" aria-label="white" sx={[{ backgroundColor: `${theme.palette.light.main} !important` }, color === 'white' && { borderColor: colors.blue[200] }]}>
                    &nbsp;
                </ToggleButton>
                <ToggleButton value="black" aria-label="black" sx={[{ backgroundColor: `${theme.palette.dark.main} !important` }, color === 'black' && { borderColor: colors.blue[200] }]} />
                <ToggleButton value="contrastText" aria-label="contrastText" sx={[{ backgroundColor: `${theme.palette.primary.contrastText} !important` }, color === 'contrastText' && { borderColor: colors.blue[200] }]} />
            </ToggleButtonGroup>
        </Box>
        <hr />
        <Box pt={2} fontWeight="bold">
            Font Size
        </Box>
        <Box mx={2} display="flex" flexDirection="row">
        <ToggleButtonGroup
                exclusive
                onChange={handleTextSizeChange}
                aria-label="font size"
                fullWidth={true}
            >
                <ToggleButton value="decrease" aria-label="decrease text size" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }]}>
                    <TextDecrease />
                </ToggleButton>
                <ToggleButton value="increase" aria-label="increase text size" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }]}>
                    <TextIncrease />
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
        <hr />
        <Box pt={2} fontWeight="bold">
            Space between lines
        </Box>
        <Box mx={2} display="flex" flexDirection="row">
            <ToggleButtonGroup
                value={spacing}
                exclusive
                onChange={handleSpacing}
                aria-label="space between lines"
                fullWidth={true}
            >
                <ToggleButton value="small" aria-label="small space between lines" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }, spacing === 'small' && { color: `${colors.grey[700]} !important`, backgroundColor: `${colors.grey[50]} !important` }]}>
                    <DensitySmall />
                </ToggleButton>
                <ToggleButton value="medium" aria-label="medium space between lines" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }, spacing === 'medium' && { color: `${colors.grey[700]} !important`, backgroundColor: `${colors.grey[50]} !important` }]}>
                    <DensityMedium />
                </ToggleButton>
                <ToggleButton value="large" aria-label="large space between lines" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }, spacing === 'large' && { color: `${colors.grey[700]} !important`, backgroundColor: `${colors.grey[50]} !important` }]}>
                    <DensityLarge />
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
        <hr />
        <Box pt={2} fontWeight="bold">
            Aligment
        </Box>
        <Box mx={2} display="flex" flexDirection="row">
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                fullWidth={true}
            >
                <ToggleButton value="left" aria-label="left aligned" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }, alignment === 'left' && { color: `${colors.grey[700]} !important`, backgroundColor: `${colors.grey[50]} !important` }]}>
                    <FormatAlignLeft />
                </ToggleButton>
                <ToggleButton value="center" aria-label="centered" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }, alignment === 'center' && { color: `${colors.grey[700]} !important`, backgroundColor: `${colors.grey[50]} !important` }]}>
                    <FormatAlignCenter />
                </ToggleButton>
                <ToggleButton value="right" aria-label="right aligned" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }, alignment === 'right' && { color: `${colors.grey[700]} !important`, backgroundColor: `${colors.grey[50]} !important` }]}>
                    <FormatAlignRight />
                </ToggleButton>
                <ToggleButton value="justify" aria-label="justified" sx={[color === 'black' && { borderColor: colors.grey[50], color: colors.grey[50] }, alignment === 'justify' && { color: `${colors.grey[700]} !important`, backgroundColor: `${colors.grey[50]} !important` }]}>
                    <FormatAlignJustify />
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
        <hr />
        <Box pt={2} fontWeight="bold">
            Text in full width <Switch onChange={handleFullWidthText} sx={[color === 'black' && { '& .MuiSwitch-track': { backgroundColor: colors.grey[50] } }]} />
        </Box>
    </Box>
    </Drawer>;
}