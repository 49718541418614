import { useEffect, useState, createRef, useRef } from "react";
import { Avatar, AvatarGroup, Badge, Box, Button, Card, CardContent, CardMedia, Chip, colors, Divider, Drawer, Fab, Grid, IconButton, LinearProgress, ListItemText, Menu, MenuItem, MenuList, Skeleton, SpeedDial, SpeedDialAction, Switch, TextField, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AccountBalanceWallet, ArrowBack, ArrowForward, Article, ArticleOutlined, ChatBubble, Close, CoPresent, DensityLarge, DensityMedium, DensitySmall, DoneAll, FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, Home, NotificationsActive, People, Person, PlayArrow, RateReview, RestartAlt, School, Send, Settings, TextDecrease, TextIncrease, Topic, Chat, MenuBook, Groups, ThumbsUpDown, Create, QuestionAnswer, DesignServices, Autorenew, KeyboardArrowUp, KeyboardArrowDown, ArrowDownward } from "@mui/icons-material";

import { getUserInfo } from 'infra/storage';
import TesteChipGameTest from "./teste-chip";
import ChatBody from "./chat.body";

export default function GameComponent({ currentGame, setIsGameOpen, isOpenTextSection, color, colorSidebar, textColorPalette, colorBars, gameType, setGameType, isDesktop, setShowGame }) {
    const [currentPoints, setCurrentPoints] = useState(0);
    const [isDisabledSendButton, setIsDisabledSendButton] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState(false);
    const [currentState, setCurrentState] = useState(null);
    const [lastState, setLastState] = useState(null);
    const [isFinalState, setIsFinalState] = useState(false);
    const [isOpenAnswer, setIsOpenAnswer] = useState(false);
    const newOpenAnswer = createRef();
    const [countNewMessages, setCountNewMessages] = useState(0);
    console.log('currentState', currentState);

    const defaultCharacter = {
        happy: '/happy-character.png',
        neutral: '/neutral-character.png',
        negative: 'sad-character.png'
    };

    let gameStates = null;
    try {
        gameStates = JSON.parse(currentGame?.content);
        console.log('gameStates', gameStates);
    } catch {
        gameStates = null;
    }

    useEffect(() => {
        if (gameStates && gameStates?.length > 0) {
            const state = gameStates[0];
            setCurrentState(state);
        }
    }, []);

    useEffect(() => {
        const oldMessageCount = messages.length;
        let aux = messages;
        if (currentState?.type === 'final') {
            setIsFinalState(true);
            aux.push({
                userId: "character",
                photo: `${process.env.REACT_APP_BACKEND_URL}/${currentState.charImg}`,
                name: 'Character',
                currentUser: false,
                loading: true,
                message: 'You can click on X to close this chat and resume the book'
            });
            setMessages(aux);
        } else if (currentState && lastState?.id !== currentState?.id) {
            const characterImg = currentState?.characteres?.default || defaultCharacter.neutral;
            if (currentState.text) {
                if (Array.isArray(currentState.text)) {
                    currentState.text.forEach(stateText => aux.push({
                        userId: "character",
                        photo: characterImg,
                        name: 'Character',
                        currentUser: false,
                        loading: false,
                        message: stateText
                    }));
                    console.log('aux', aux);
                } else {
                    aux.push({
                        userId: "character",
                        photo: characterImg,
                        name: 'Character',
                        currentUser: false,
                        loading: false,
                        message: currentState.text
                    });
                }
            }
            if (currentState.stateImage) {
                aux.push({
                    userId: "character",
                    photo: characterImg,
                    name: 'Character',
                    currentUser: false,
                    loading: false,
                    image: `${process.env.REACT_APP_BACKEND_URL}/${currentState.stateImage}`
                });
            }

            setCountNewMessages(countNewMessages + aux.length - oldMessageCount);
            setMessages(aux);
        }

        setTimeout(() => {
            aux.map(message => {
                message.loading = false;
            });
            setIsDisabledSendButton(false);
            setMessages(aux);
        }, 500);

        setIsOpenAnswer(Array.isArray(currentState?.wordOptions) && currentState?.wordOptions.length > 0 && currentState?.type !== 'final');
    }, [currentState]);

    useEffect(() => {
        if (messages.length > 1) {
            setIsDisabledSendButton(true);
            const oldMessageCount = messages.length;
            let aux = messages;
            let countRightMessage = currentPoints;
            let countWrongMessage = 0;
            let nextStateIdx = 1;
            let responseIsRight = 0;

            if (!isOpenAnswer) {
                setCurrentState(gameStates[currentState.nextStateIdx]);
                return;
            }

            Object.keys(currentState?.responses).map(key => {
                const response = currentState?.responses[key];

                if (key === 'default') {
                    return;
                }

                if (aux[aux.length - 1].message.trim() == response?.input.trim()) {
                    let photo = null;
                    if (response?.charImg) {
                        photo = `${process.env.REACT_APP_BACKEND_URL}/${response.charImg}`;
                    } else if (response?.type === 1) {
                        photo = defaultCharacter.happy;
                    } else if (response?.type === 2) {
                        photo = defaultCharacter.negative;
                    } else {
                        photo = defaultCharacter.neutral;
                    }

                    aux.push({
                        userId: "character",
                        photo,
                        name: 'Character',
                        currentUser: false,
                        loading: true,
                        message: response.output
                    });

                    nextStateIdx = response.nextStateIdx;

                    if (response.points > 0) {
                        countRightMessage = countRightMessage + response.points;
                        responseIsRight = 1;
                        setCurrentPoints(countRightMessage);
                    } else if (response?.type === 1) {
                        countRightMessage = countRightMessage + 1;
                        responseIsRight = 1;
                        setCurrentPoints(countRightMessage);
                    } else {
                        countWrongMessage = countWrongMessage + 1;
                    }

                    return;
                }
            });


           // if (countWrongMessage === 0 && countRightMessage === 0) {
            if (responseIsRight === 0){
                aux.push({
                    userId: "character",
                    photo: currentState.charImg ? `${process.env.REACT_APP_BACKEND_URL}/${currentState.charImg}` : defaultCharacter.neutral,
                    name: 'Character',
                    currentUser: false,
                    loading: true,
                    message: currentState?.responses?.default
                });
            }

            setCountNewMessages(countNewMessages + aux.length - oldMessageCount);

            setMessages(aux);

            if (countRightMessage >= Number.parseInt(currentState.minPoints)) {
                setLastState(currentState);
                setCurrentState(gameStates[nextStateIdx]);
                setCurrentPoints(0);
                return;
            }

            setTimeout(() => {
                const lastMessageIndex = aux.length - 1;
                aux[lastMessageIndex].loading = false;
                setIsDisabledSendButton(false);
                setMessages(aux);
            }, 1000);
        }
    }, [newMessage]);

    if (!gameStates || gameStates?.length < 1) {
        return <></>;
    }

    return <Box p={3} display="flex" flexDirection="column" height="100%" width="100%" bgcolor={colorSidebar} color={textColorPalette} sx={{ width: { md: isOpenTextSection ? '500px' : '100%' } }}>
                <Box py={1.5} px={2} width="100%" fontWeight="bold" display="flex" alignItems="center" justifyContent="space-between" flex bgcolor={colorSidebar} color={textColorPalette}>
                    <Box display="flex" flexGrow={1} justifyContent="center">
                        <Typography variant="h4">Chat with the Character</Typography>
                    </Box>
                    {isFinalState && (<IconButton
                        edge="start"
                        color="primary"
                        aria-label="close"
                        onClick={() => {
                            setIsGameOpen(false);
                            setShowGame(false);
                        }}
                        sx={[color === 'black' && { color: colors.grey[50] }]}
                    >
                        <Close />
                    </IconButton>)}
                </Box>
                <ChatBody type="game" countNewMessages={countNewMessages} setCountNewMessages={setCountNewMessages} messages={messages} isOpenTextSection={isOpenTextSection} color={color} textColorPalette={textColorPalette} colorBars={colorBars} />
            <Divider />
            <Box mx={1}></Box>
            {isOpenAnswer && (<TesteChipGameTest
                options={currentState?.wordOptions} messages={messages} setMessages={setMessages} isDisabledSendButton={isDisabledSendButton} newMessage={newMessage} setNewMessage={setNewMessage} 
                />
            )}
            {!isOpenAnswer && !isFinalState && (
                <Grid container mb={2}>
                    <Grid item xs={8}>
                        <TextField inputRef={newOpenAnswer} fullWidth
                            label="Type here to write a comment." helperText="Click the Send Message button to submit it to the discussion."
                            FormHelperTextProps={{sx: { color: textColorPalette }}} InputLabelProps={{sx: { color: textColorPalette }}}
                            sx={{ '& .MuiOutlinedInput-notchedOutline': {borderColor: textColorPalette}}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button fullWidth variant="contained" startIcon={<Send />} disabled={newOpenAnswer?.current?.value?.length < 2 || isDisabledSendButton}
                            onClick={() => {
                                let aux = messages;
                                aux.push({
                                    userId: "student",
                                    photo: `${process.env.REACT_APP_BACKEND_URL}/${getUserInfo().avatar}`,
                                    name: 'You',
                                    currentUser: true,
                                    loading: false,
                                    message: newOpenAnswer.current.value
                                });
                                setMessages(aux);
                                setNewMessage(!newMessage);
                            }}
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            )}
    </Box>;
}