import { AccountBox, Settings } from "@mui/icons-material";
import { Avatar, Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

export default function ProfileHome({ profileMenuAnchor, handleCloseProfileMenu, setIsOpenAvatarDialog, handleOpenConfigMenuDrawer }) {
    const handleChangeAvatarClick = () => {
        setIsOpenAvatarDialog(true);
        handleCloseProfileMenu();
    };

    const handleConfigClick = () => {
        handleOpenConfigMenuDrawer();
        handleCloseProfileMenu();
    }
    return <Menu
        anchorEl={profileMenuAnchor}
        open={Boolean(profileMenuAnchor)}
        onClose={handleCloseProfileMenu}
    >
        <MenuItem onClick={() => handleChangeAvatarClick()} sx={{ width: 200, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <AccountBox />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    Change Avatar
                </Box>
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleConfigClick()} sx={{ width: 200, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <Settings />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    Settings
                </Box>
            </ListItemText>
        </MenuItem>
    </Menu>;
}