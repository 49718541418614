import { Avatar, Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

import books from "mocks/book";

export default function NotificationsHome({ notificationMenuAnchor, handleCloseNotificationMenu }) {
    return <Menu
        anchorEl={notificationMenuAnchor}
        open={Boolean(notificationMenuAnchor)}
        onClose={handleCloseNotificationMenu}
    >
        <MenuItem onClick={handleCloseNotificationMenu} sx={{ width: 320, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <Avatar alt={books[0].title} src={books[0].cover} />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    You have <Box component="span" sx={{ fontWeight: 'bold' }}>30 new messages</Box> on {books[0].title}
                </Box>
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationMenu} sx={{ width: 320, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <Avatar alt={books[0].title} src={books[0].cover} />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    You have <Box component="span" sx={{ fontWeight: 'bold' }}>2 new peer-reviews</Box> on {books[0].title}
                </Box>
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationMenu} sx={{ width: 320, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <Avatar alt={books[1].title} src={books[1].cover} />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    You have <Box component="span" sx={{ fontWeight: 'bold' }}>30 new messages</Box> on {books[1].title}
                </Box>
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationMenu} sx={{ width: 320, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <Avatar alt={books[1].title} src={books[1].cover} />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    You have <Box component="span" sx={{ fontWeight: 'bold' }}>2 new peer-reviews</Box> on {books[1].title}
                </Box>
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationMenu} sx={{ width: 320, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <Avatar alt={books[2].title} src={books[2].cover} />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    You have <Box component="span" sx={{ fontWeight: 'bold' }}>30 new messages</Box> on {books[2].title}
                </Box>
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationMenu} sx={{ width: 320, whiteSpace: 'normal' }}>
            <ListItemIcon>
                <Avatar alt={books[2].title} src={books[2].cover} />
            </ListItemIcon>
            <ListItemText>
                <Box ml={1}>
                    You have <Box component="span" sx={{ fontWeight: 'bold' }}>2 new peer-reviews</Box> on {books[2].title}
                </Box>
            </ListItemText>
        </MenuItem>
    </Menu>;
}