import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, Divider, Paper, Snackbar, TextField, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { PAGES } from 'routes';
import { storeCredentials, storeUserInfo } from 'infra/storage';
import loginService from 'infra/services/login';
import ClassStyles from 'view/style';
import { useTheme } from '@mui/material';

export default function LoginPage() {
    const theme = useTheme();
    const classCss = ClassStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const disabledLogin = login.length === 0 || password.length === 0;

    const onOpenSite = event => {
        event.preventDefault();
        window.open('https://imapbook.com');
    };
    const onLogin = event => {
        event.preventDefault();
        setLoading(true);
        loginService({ login, password })
            .then(response => {
                const { apiKey, avatar, bookclub, globalLeaderboardCount, globalLeaderboardPosition, name, notifications } = response;
                storeCredentials(apiKey);
                storeUserInfo({ avatar, globalLeaderboardCount, globalLeaderboardPosition, name, notifications, bookclub });
                navigate(PAGES.HOME);
            })
            .catch(e => {
                setError(e.errors[0].message);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const onChangeLogin = event => {
        setLogin(event.target.value);
    }
    const onChangePassword = event => {
        setPassword(event.target.value);
    }

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box className={classCss.loginComponent}>
            <Paper elevation={3} sx={{ width: '100%' }}>
                <Box p={3.5} display="flex" flexDirection='column' alignItems='center' justifyContent='center'>
                    <Box width={220} height={128} className={classCss.logoFull}></Box>
                    <Box pt={3} width="100%">
                        <TextField fullWidth required id='username' disabled={loading} label='username' variant="outlined" onChange={onChangeLogin} />
                    </Box>
                    <Box pt={3} width="100%">
                        <TextField fullWidth required id='senha' disabled={loading} label='Password' variant="outlined" type="password" onChange={onChangePassword} />
                    </Box>
                    <Box pt={5} pb={3} width="100%">
                        <LoadingButton fullWidth variant='contained' loading={loading} disabled={disabledLogin} onClick={onLogin}>Log In</LoadingButton>
                    </Box>
                    <Box pb={3} width="100%">
                        <Button fullWidth variant='outlined' disabled onClick={onLogin}>Recover Password</Button>
                    </Box>
                    <Divider width="100%" />
                    <Box pt={3} width="100%">
                        Found a bug? <Box component="a" color="primary" onClick={() => window.open('https://forms.gle/8YGetqpfRxy3q2Ay5')}
                            sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                        >Report here</Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
        <Typography variant='caption'>
            <Box textAlign="center">
                Copyright © <Box component="a" onClick={onOpenSite}>iMapBook</Box> 2022.
            </Box>
        </Typography>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={error.length > 0}
            autoHideDuration={5000}
            onClose={() => setError('')}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
        </Snackbar>
    </Box>;
}