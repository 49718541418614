import React, { useEffect, useState } from "react";
import { ArrowBack, ArrowDownward, Close, ExpandLess, ExpandMore, Send } from "@mui/icons-material";
import { Avatar, AvatarGroup, Badge, Box, Button, Card, CardMedia, CardContent, Chip, colors, Divider, Grid, IconButton, TextField, Typography, Rating, CardHeader, Collapse, CircularProgress } from "@mui/material";
import { getUserInfo } from "infra/storage";
import { LoadingButton } from "@mui/lab";
import postFeedback from "infra/services/new-feedback";

function PeerReviewCard({ peerReview, bookId, topicId, getPeerReview, setError }) {
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChangeInput = event => {
        setFeedback(event.target.value);
    }

    useEffect(() => {
        if (peerReview?.rating) {
            setLoading(false);
        }
    }, [peerReview]);

    const handleSubmitNewComment = event => {
        event.preventDefault();
        setLoading(true);
        setOpen(false);
        postFeedback({
            bookId,
            topicId,
            bookclubId: peerReview.bookclub.id,
            rating,
            response: feedback
        })
            .then(() => getPeerReview())
            .catch(e => setError(e.errors[0].message));
    };

    return <Box key={peerReview.id} mb={2.5}>
        <Card style={{ backgroundColor: peerReview.rating !== null ? '#EEE' : 'none' }}>
            <CardContent>
                <Box display="flex" flexDirection="row">
                    <Box flexGrow={1}>
                        <Typography variant="h6">{peerReview.bookclub.name} {loading && (<CircularProgress size={20} />)}</Typography>
                        <Box>{peerReview.timestamp}</Box>
                        {peerReview.rating !== null ? `Rating: ${peerReview.rating}` : ''}
                    </Box>
                    {!loading && (<IconButton
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>)}
                </Box>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <iframe src={`https://editor.imapbook.org/${bookId}_${topicId}_${peerReview.bookclub.id}/read-only`} height={320}></iframe>
                    <Box py={2}>
                        <Typography variant="legend">
                            Rating
                        </Typography>
                        <Rating value={peerReview.rating !== null ? peerReview.rating : undefined} readOnly={peerReview.rating !== null} precision={0.5} onChange={(event, newValue) => setRating(newValue)} />
                    </Box>
                    <Box pb={2}>
                        <Typography variant="legend">
                            Your feedback
                        </Typography>
                        <TextField
                            multiline
                            fullWidth
                            rows={8}
                            value={peerReview.rating !== null ? peerReview.response : undefined}
                            readOnly={peerReview.rating !== null}
                            onChange={handleChangeInput}
                        />
                    </Box>
                    {peerReview.rating === null && (
                        <Box pb={3}>
                            <LoadingButton fullWidth variant="contained" startIcon={<Send />} onClick={handleSubmitNewComment} loading={loading} disabled={rating === 0 || feedback.length === 0}>Send feedback</LoadingButton>
                        </Box>
                    )}
                </Collapse>
            </CardContent>
        </Card>
    </Box>;
}

export default function BookDiscussionSection({ discussions, chats, bookId, peerReviews, getPeerReview, setError, isDesktop, isOpenChatDrawer, isOpenTextSection, setIsOpenCrewDiscussionDrawer, colorSidebar, textColorPalette, colorPalette, color, isPeerReview }) {
    const [currentDiscussion, setCurrentDiscussion] = useState(null);

    useEffect(() => {
        if (discussions && discussions.length === 1) {
            setCurrentDiscussion(discussions[0]);
        }
    }, []);

    return <Box pt={3} pl={3} pb={6} display="flex" flexDirection="column" height="100%" bgcolor={colorSidebar} color={textColorPalette}
        sx={{ width: { md: isOpenTextSection ? '500px' : '100%' } }}
    >
        {((isDesktop && (isOpenChatDrawer || isOpenTextSection)) || currentDiscussion) && (<Box mb={2} display="flex" flexDirection='row' justifyContent={currentDiscussion ? "space-between" :"end"}>
            {currentDiscussion && (
                <IconButton
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    onClick={() => setCurrentDiscussion(null)}
                    sx={[color === 'black' && { color: colors.grey[50] }]}
                >
                    <ArrowBack />
                </IconButton>
            )}
            <Box mx={2} flexGrow={1} fontWeight="bold" display="flex" alignItems="center" justifyContent="center">
                {isPeerReview ? "Peer Review" : "Co-write"}
            </Box>
            {isDesktop && (isOpenChatDrawer || isOpenTextSection) && (<IconButton
                edge="start"
                color="primary"
                aria-label="menu"
                onClick={() => setIsOpenCrewDiscussionDrawer(false)}
                sx={[color === 'black' && { color: colors.grey[50] }]}
            >
                <Close />
            </IconButton>)}
        </Box>)}
        <Box flexGrow={1} pr={3} height={window.innerHeight - 190 - 40} sx={{ overflow: 'auto', mb: 2, width: { xs: '100%', md: isOpenTextSection ? '400px' : '100%' } }}>
            {currentDiscussion ? isPeerReview ? (<>
                <Box pt={2}></Box>
                <Typography variant="h6">
                    {currentDiscussion.question}
                </Typography>
                <Box pt={2}></Box>
                {peerReviews.topics[currentDiscussion.topic_id].peer_review.map(peerReview => (<PeerReviewCard peerReview={peerReview} bookId={bookId} topicId={currentDiscussion.topic_id} getPeerReview={getPeerReview} setError={setError} />))}
            </>) : (<>
                <Box pt={2}></Box>
                <Typography variant="subtitle2">
                    {currentDiscussion.question}
                </Typography>
                <Box pt={2}></Box>
                <Typography variant="subtitle2">
                    Use the area below to collaboratively write a response to the prompt. Teammates can coordinate to write & edit here at the same time. Changes save automatically. When done, add the title SUBMITTED.
                </Typography>
                <Box flexGrow={1}>
                    <iframe src={currentDiscussion.collaborative === '0' && currentDiscussion.link?.length > 0 ?
                        currentDiscussion.link :
                        `https://editor.imapbook.org/${bookId}_${currentDiscussion.topic_id}_${getUserInfo()?.bookclub}`
                    } height={window.innerHeight - 400}></iframe>
                </Box>
            </>) : (<Box>
                    <Box pt={2}></Box>
                    <Typography variant="subtitle1">
                        Please, choose one of the topics bellow to start the Co-writing.
                    </Typography>
                    <Box pt={2}></Box>
                    {discussions.map(discussion => {
                        if (discussion.collaborative === '0') {
                            return <></>;
                        }
                        const discussionChat = chats.topics.filter(chat => chat.topic_id == discussion.topic_id)[0];
                        let discussionUsers = [];

                        discussionChat?.messages.map(chat => {
                            if (discussionUsers.filter(user => user.user_name === chat.user_name).length === 0 && (
                                !isPeerReview ||
                                (isPeerReview && !chat.current_user)
                            )) {
                                discussionUsers.push({
                                    user_name: chat.user_name,
                                    avatar : chat.avatar,
                                    current_user: chat.current_user
                                });
                            }
                        });

                        return <Card key={discussion.topic_id} sx={{ display: 'flex', width: '100%', mb: 3, backgroundColor: colorPalette, cursor: 'pointer' }} onClick={() => setCurrentDiscussion(discussion)}>
                            <Box display='flex' alignItems="center">
                                <AvatarGroup max={2} sx={{ width: 130, justifyContent: "center" }}>
                                    {discussionUsers[0] && (<Avatar alt={discussionUsers[0].user_name} src={`${process.env.REACT_APP_BACKEND_URL}/${discussionUsers[0].avatar}`} />)}
                                    {discussionUsers[1] && (<Avatar alt={discussionUsers[1].user_name} src={`${process.env.REACT_APP_BACKEND_URL}/${discussionUsers[1].avatar}`} />)}
                                </AvatarGroup>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Box color={textColorPalette}>{discussion.question}</Box>
                                </CardContent>
                            </Box>
                        </Card>
                    })}
                </Box>)
            }
        </Box>
    </Box>;
}