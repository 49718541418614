import wretch from 'wretch';

import { getCredentials } from 'infra/storage';
import {
  PARSE_ERROR,
  NETWORK_ERROR,
  translateStatusCode
} from './http-message';

export async function getHeaders(headers) {
  const auth = getCredentials();

  return {
    ...headers,
    Authorization: `ApiKey ${auth}`
  };
}

export const headerMiddleware = next => async (url, opts) => {
  const headers = opts?.headers;

  const optsHeader = {
    ...opts,
    headers: await getHeaders(headers),
    mode: "cors"
  };

  return next(url, optsHeader);
};

const api = wretch()
  .url(`${process.env.REACT_APP_BACKEND_URL}`)
  .content('application/json')
  .middlewares([headerMiddleware])
  .catcher('Error', err =>
    Promise.reject(translateStatusCode(err.response.status, err.message, err))
  )
  .catcher('FetchError', () => Promise.reject(PARSE_ERROR))
  .catcher('__fromFetch', () => Promise.reject(NETWORK_ERROR));

export default api;