import { createTheme } from "@mui/material";

export const primary = {
    main: '#4A6964',
    contrastText: '#FCFCF5',
    contrastTextDark: '#F8F8E8'
};

const theme = createTheme({
    palette: {
        primary,
        dark: {
          main: '#121212',
          contrastText: '#FFF',
        },
        light: {
            main: '#fff',
            contrastText: '#121212',
        },
      },
});

export default theme;