const credentialsKey = 'IMAPBOK_CREDENTIALS';
const userInfoKey = 'IMAPBOK_USER_INFOS';

export function getCredentials() {
    return localStorage.getItem(credentialsKey);
}

export function storeCredentials(credentials) {
    localStorage.setItem(credentialsKey, credentials);
}

export function removeCredentials() {
    localStorage.removeItem(credentialsKey);
}

export function updateCredentials(apiKey) {
    localStorage.setItem(credentialsKey, apiKey);
}

export function getUserInfo() {
    const userInfo = localStorage.getItem(userInfoKey);
    return JSON.parse(userInfo) || {};
}

export function storeUserInfo(userInfo) {
    localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
}

export function removeUserInfo() {
    localStorage.removeItem(userInfoKey);
}

export function updateUserInfo(newUserInfo) {
    const userInfo = getUserInfo();

    localStorage.setItem(userInfoKey, JSON.stringify({
        ...userInfo,
        ...newUserInfo
    }));
}