export default function TextBook({ text, dictionary }) {
    let richText = text.replace('</p>', '');
    richText = richText.replace(/src="data/g, `src="${process.env.REACT_APP_BACKEND_URL}/data`);
    richText = richText.replace(/src='data/g, `src='${process.env.REACT_APP_BACKEND_URL}/data`);

    Object.keys(dictionary).map(key => {
        const dictionaryComponent = `<span class="defWord">${key}<span class="tooltiptext">${dictionary[key]}</span></span>`;
        richText = richText.replace(new RegExp(key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'ig'), dictionaryComponent);
    });

    richText = <p dangerouslySetInnerHTML={{ __html: richText }} />;

    return richText;
}