import React, { useState } from "react";
import { ArrowBack, ArrowDownward, Close, ExpandLess, ExpandMore, Send } from "@mui/icons-material";
import { Avatar, AvatarGroup, Badge, Box, Button, Card, CardMedia, CardContent, Chip, colors, Divider, Grid, IconButton, TextField, Typography, Rating, CardHeader, Collapse } from "@mui/material";

export default function LeaderboardBookCard({ book, leaderboard, type, colorBars, textColorPalette }) {
    const [open, setOpen] = useState(false);
    const rankPosition = leaderboard.filter(pos => pos.isCurrentUserBookClub)[0].position;
    const rating = leaderboard.filter(pos => pos.isCurrentUserBookClub)[0].rating;

    const id = type === 'general' ? 'leaderboard_general' : `leaderboard_book_${book.id}`;
    const title = type === 'general' ? 'General Leaderboard' : book.title;
    const coverLocation = type === 'general' ? '/full_logo.png' : `${process.env.REACT_APP_BACKEND_URL}/${book.coverLocation}`;
    const isDarkTheme = colorBars === "#212121";

    const getColorPosition = position => {
        if (position < 3) {
            return isDarkTheme ? colors.green[300] : colors.green[900];
        } else if (position < 5) {
            return isDarkTheme ? colors.yellow[300] : colors.yellow[900];
        }

        return isDarkTheme ? colors.red[300] : colors.red[900];
    }

    return <Box key={id} mb={2.5}>
        <Card elevation={5} sx={{ width: '100%', backgroundColor: colorBars, color: textColorPalette }}>
            <Box display='flex' flexDirection='row'>
                <CardMedia
                        component="img"
                        sx={{ width: 75 }}
                        image={coverLocation}
                        alt={title}
                    />
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', pr: 2 }}>
                    <CardContent>
                        <Box display="flex" flexDirection="row">
                            <Box flexGrow={1}>
                                <Typography variant="subtitle1">{title}</Typography>
                                <Typography variant="subtitle2">
                                    <Box>Score: <Rating size="small" value={rating} readOnly precision={0.5} /></Box>
                                    Rank position: <Box component="span" sx={{ color: getColorPosition(rankPosition - 1) }}>{rankPosition}</Box>
                                </Typography>
                            </Box>
                            <IconButton
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                    </CardContent>
                </Box>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box p={2}>{leaderboard.map((leaderboard, index) => {
                    if (index > 2) return;

                    return <Box
                        key={leaderboard.id}
                        sx={{ color: getColorPosition(index), fontWeight: index === rankPosition - 1 ? 'bold' : 'none' }}
                    >
                        <Typography variant="subtitle1" sx={{ fontWeight: index === rankPosition - 1 ? 'bold' : 'none' }}>{leaderboard.position}) {leaderboard.name}</Typography>
                        <Box pl={2.5} pb={1}>
                            <Typography variant="subtitle2" sx={{ fontWeight: index === rankPosition - 1 ? 'bold' : 'none' }}>
                                {leaderboard.students}
                                <Box>
                                    <Rating size="small" value={leaderboard.rating} readOnly precision={0.5} /> ({leaderboard.rating})
                                </Box>
                            </Typography>
                        </Box>
                    </Box>;
                })}</Box>
            </Collapse>
        </Card>
    </Box>;
}