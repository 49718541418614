import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    logo: {
        backgroundImage: 'url(/logo.png)',
        backgroundSize: 'cover',
    },
    logoFull: {
        backgroundImage: 'url(full_logo.png)',
        backgroundSize: 'cover',
    },
    loginComponent: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '25%',
            width: '50%'
        },
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export default useStyles;