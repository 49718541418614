export const PERMISSION_DENIED_MESSAGE =
  "You don't have permission to access this content, please ask the admin";
const NETWORK_ERROR_MESSAGE = 'Error to access the server';
const NOT_FOUND_MESSAGE = 'Resource not found';
const DEFAULT_MESSAGE_500 = 'Error in the server, please try again';
const DEFAULT_MESSAGE_400 = 'Invalid request';

const PARSE_ERROR = {
    errors: [
      {
        message: 'Error to parse the response'
      }
    ],
    code: 0
  };
  
  const NETWORK_ERROR = {
    errors: [
      {
        message: NETWORK_ERROR_MESSAGE
      }
    ],
    code: 0
  };
  
  const RESPONSE_MESSAGES_BY_CODE = {
    400: DEFAULT_MESSAGE_400,
    401: PERMISSION_DENIED_MESSAGE,
    403: PERMISSION_DENIED_MESSAGE,
    404: NOT_FOUND_MESSAGE,
    500: DEFAULT_MESSAGE_500
  };
  
  function translateStatusCode(status, message = '') {
    if (RESPONSE_MESSAGES_BY_CODE[status]) {
      const messageError = RESPONSE_MESSAGES_BY_CODE[status];
  
      return {
        errors: [{ message: messageError }],
        code: status
      };
    }
  
    try {
        if (message) {
        const json = JSON.parse(message);
        const { errors } = json || {};
    
        if (errors === undefined) {
            const REMOVE_QUOTES = new RegExp(/('|")/g);
            const messageError = message.replace(REMOVE_QUOTES, '');
            return { errors: [{ message: messageError }], code: status };
        }
    
        return { errors, code: status };
        }
    } catch {
        return { errors: [{ message }], code: 0 };
    }
  
    return { errors: [], code: 0 };
  }
  
  export { PARSE_ERROR, NETWORK_ERROR, translateStatusCode };