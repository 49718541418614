import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BookPage from 'view/book';
import HomePage from 'view/home';
import LoginPage from 'view/login';

export const PAGES = {
    LOGIN: '/',
    HOME: '/home',
    BOOK: '/book/:id/:page'
};

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={PAGES.LOGIN} element={<LoginPage />} />
        <Route exact path={PAGES.HOME} element={<HomePage />} />
        <Route exact path={PAGES.BOOK} element={<BookPage />} />
      </Routes>
    </BrowserRouter>
  );
};