import api from 'infra/config/api';

export default function loginService({ login, password }) {
    return api
    .url('/login.php')
    .post(JSON.stringify({
        login,
        password
    }))
    .json();
}